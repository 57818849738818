import { cn } from "~/utils/cn";

const header1Variants = {
  "extra-extra-small": {
    text: "text-lg sm:text-xl ",
    spacing: "mb-3 sm:mb-4",
  },
  "extra-small": {
    text: "text-xl sm:text-2xl ",
    spacing: "mb-3 sm:mb-4",
  },
  small: {
    text: "text-2xl sm:text-3xl",
    spacing: "mb-4 sm:mb-5",
  },
  base: {
    text: "text-3xl sm:text-4xl",
    spacing: "mb-5 sm:mb-6",
  },
  large: {
    text: "text-3xl sm:text-4xl lg:text-5xl",
    spacing: "mb-3 sm:mb-4",
  },
  "extra-large": {
    text: "text-4xl sm:text-5xl lg:text-6xl",
    spacing: "mb-7 sm:mb-8",
  },
  "extra-extra-large": {
    text: "text-4xl sm:text-5xl lg:text-7xl",
    spacing: "mb-8 sm:mb-9",
  },
};

const header2Variants = {
  "extra-small": {
    text: "text-lg sm:text-xl",
    spacing: "mb-2 sm:mb-3",
  },
  small: {
    text: "text-xl sm:text-2xl",
    spacing: "mb-3 sm:mb-4",
  },
  base: {
    text: "text-2xl sm:text-3xl",
    spacing: "mb-4 sm:mb-5",
  },
  large: {
    text: "text-3xl sm:text-4xl",
    spacing: "mb-5 sm:mb-6",
  },
  "extra-large": {
    text: "text-4xl sm:text-5xl",
    spacing: "mb-6 sm:mb-7",
  },
};

const header3Variants = {
  "extra-small": {
    text: "text-md sm:text-lg",
    spacing: "mb-1 sm:mb-2",
  },
  small: {
    text: "text-lg sm:text-xl leading-6 sm:leading-normal",
    spacing: "mb-2 sm:mb-3",
  },
  base: {
    text: "text-xl sm:text-2xl",
    spacing: "mb-2 sm:mb-3",
  },
  large: {
    text: "text-2xl sm:text-3xl",
    spacing: "mb-4 sm:mb-5",
  },
  "extra-large": {
    text: "text-2xl sm:text-3xl lg:text-4xl",
    spacing: "mb-5 sm:mb-6",
  },
};

const header4Variants = {
  "extra-small": {
    text: "text-sm sm:text-base",
    spacing: "mb-0.5 sm:mb-1",
  },
  small: {
    text: "text-base sm:text-lg",
    spacing: "mb-1 sm:mb-2",
  },
  base: {
    text: "text-lg sm:text-xl",
    spacing: "mb-2",
  },
  large: {
    text: "text-xl sm:text-2xl",
    spacing: "mb-4",
  },
  "extra-large": {
    text: "text-2xl sm:text-3xl",
    spacing: "mb-4",
  },
  "extra-extra-large": {
    text: "text-3xl sm:text-4xl",
    spacing: "mb-5",
  },
  "extra-extra-extra-large": {
    text: "text-4xl sm:text-5xl",
    spacing: "mb-6",
  },
  custom: {
    text: "",
    spacing: "mb-6",
  },
};

const headerAttributes = "font-title text-bright font-semibold";

type HeaderProps = {
  className?: string;
  children: React.ReactNode;
  spacing?: boolean;
  textCenter?: boolean;
  fontSans?: boolean;
  id?: string;
};

type Header1Props = HeaderProps & {
  variant: keyof typeof header1Variants;
};

type Header2Props = HeaderProps & {
  variant: keyof typeof header2Variants;
};

type Header3Props = HeaderProps & {
  variant: keyof typeof header3Variants;
};

type Header4Props = HeaderProps & {
  variant: keyof typeof header4Variants;
};

export function Header1({
  variant,
  spacing = false,
  textCenter,
  className,
  children,
  id,
}: Header1Props) {
  return (
    <h1
      className={cn(
        headerAttributes,
        header1Variants[variant].text,
        spacing === true && header1Variants[variant].spacing,
        textCenter ? "text-center" : "",
        "text-balance",
        className
      )}
      id={id}
    >
      {children}
    </h1>
  );
}

export function Header2({
  variant,
  spacing = false,
  textCenter,
  className,
  children,
  id,
}: Header2Props) {
  return (
    <h2
      className={cn(
        headerAttributes,
        header2Variants[variant].text,
        spacing === true && header2Variants[variant].spacing,
        textCenter ? "text-center" : "",
        className
      )}
      id={id}
    >
      {children}
    </h2>
  );
}

export function Header3({
  variant,
  spacing = false,
  fontSans = false,
  textCenter,
  className,
  children,
  id,
}: Header3Props) {
  return (
    <h3
      className={cn(
        headerAttributes,
        header3Variants[variant].text,
        spacing === true && header3Variants[variant].spacing,
        textCenter ? "text-center" : "",
        fontSans ? "font-sans" : "",
        className
      )}
      id={id}
    >
      {children}
    </h3>
  );
}

export function Header4({
  variant,
  spacing = false,
  textCenter,
  className,
  children,
  id,
}: Header4Props) {
  return (
    <h4
      className={cn(
        headerAttributes,
        header4Variants[variant].text,
        spacing === true && header4Variants[variant].spacing,
        textCenter ? "text-center" : "",
        className
      )}
      id={id}
    >
      {children}
    </h4>
  );
}
